import React from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FadeInSection from "../fadeinsection";

const FullWidthImage = ({ data }) => {
  return (
    <FadeInSection>
      <section className="full-width-image section-mb">
        <LazyLoadImage
          effect="blur"
          src={data.image?.sourceUrl}
          alt={data.image?.altText}
          height={600}
        />
      </section>
    </FadeInSection>
  );
};

export default FullWidthImage;
