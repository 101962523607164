import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>TRP Architects : 404</title>
        <meta name="description" content=""></meta>
        <meta name="prerender-status-code" content="404"></meta>
      </Helmet>
      <div className="container not-found-container">
        <h2 className="title-center">Page not found</h2>
        <p>
          The page that you are looking for cannot be found. Please check the
          URL or use the navigation at the top of the page to find what you are
          looking for
        </p>
        <div className="link-container">
          <Link to="/">Go back</Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
