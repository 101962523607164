import { gql } from "@apollo/client";

const STUDIO_PAGE_QUERY = gql`
  query studioPageQuery {
    studio: pageBy(uri: "studio") {
      title
      content
      featuredImage {
        node {
          altText
          sourceUrl(size: MEDIUM)
        }
      }
    }
    awards(first: 48) {
      nodes {
        id
        title
        content
        customAwardFields {
          project
          linkedProject {
            ... on Project {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
`;

export default STUDIO_PAGE_QUERY;
