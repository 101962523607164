import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import SINGLE_PROJECT_QUERY from "../quaries/singleProjectQuery";

import FullWidthImage from "../components/sections/FullWidthImage";
import IntroText from "../components/sections/IntroText";
import SideBySideImages from "../components/sections/SideBySideImages";
import SingleImage from "../components/sections/SingleImage";
import Text from "../components/sections/Text";
import nextArrow from "../assets/images/NextArrow.svg";
import prevArrow from "../assets/images/PreviousArrow.svg";
import { Helmet } from "react-helmet";

const getPageSection = (section, index) => {
  switch (section.elementType) {
    case "Intro text":
      return <IntroText key={`project-section-${index}`} text={section.text} />;
    case "Text":
      return <Text key={`project-section-${index}`} text={section.text} />;
    case "Single image":
      return <SingleImage key={`project-section-${index}`} data={section} />;
    case "Side-by-side images":
      return (
        <SideBySideImages key={`project-section-${index}`} data={section} />
      );
    case "Full width image":
      return <FullWidthImage key={`project-section-${index}`} data={section} />;
    default:
      return <></>;
  }
};

const SingleProject = ({ setFooterLoad }) => {
  let { projectSlug } = useParams();
  const [prevProject, setPrevProject] = useState({});
  const [nextProject, setNextProject] = useState({});

  const [awards, setAwards] = useState([]);
  const [publications, setPublications] = useState([]);

  const { loading, error, data } = useQuery(SINGLE_PROJECT_QUERY, {
    variables: { projectSlug },
  });

  const [getProject] = useLazyQuery(SINGLE_PROJECT_QUERY);

  useEffect(() => {
    if (!loading) {
      setFooterLoad(true);
    }
    return () => {
      setFooterLoad(false);
    };
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      const projectId = data.project.id;
      const allProjects = data.allProjects.edges;
      const allAwards = data.awards.nodes;
      const allPublications =
        data.publications.customPublicationFields.publications;

      const currentIndex = allProjects.findIndex((project) => {
        return project.node.id === projectId;
      });

      setPrevProject(allProjects[currentIndex - 1]);
      setNextProject(allProjects[currentIndex + 1]);

      const curAwards = allAwards.filter(
        (award) => award.customAwardFields.linkedProject?.id === projectId
      );
      setAwards(curAwards);

      const curPublications = allPublications.filter(
        (publication) => publication.linkedProject?.id === projectId
      );
      setPublications(curPublications);
    }
  }, [loading, data]);

  return (
    <>
      {data && (
        <article className="single-project">
          <Helmet>
            <title>TRP Architects : {data.project.title}</title>
          </Helmet>
          <div className="container">
            <header>
              <h1>{data.project.title}</h1>
              <p>Projects / {data.project.title}</p>
            </header>
          </div>
          {data.project.customProjectFields.pageElements?.map(
            (section, index) => getPageSection(section, index)
          )}

          {/* Awards */}
          {awards.length > 0 && (
            <>
              <div className="container title-center mb-25">
                <h2>Awards for this project</h2>
              </div>
              <section className="awards pb-50 mb-50">
                <div className="container awards-container">
                  {awards.map((award) => (
                    <div className="award-item">
                      <div className="award-container project">
                        <h3 className="award-title">{award.title}</h3>
                        <p className="award-project">
                          {award.customAwardFields.project}
                        </p>
                        <div
                          className="award-content"
                          dangerouslySetInnerHTML={{ __html: award.content }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </>
          )}

          {/*  */}

          {/* publications */}
          {publications.length > 0 && (
            <>
              <div className="container title-center mb-25">
                <h2>Related publications</h2>
              </div>
              <section className="publications mb-50">
                <div className="container">
                  {publications.map((publication) => (
                    <div className="outer">
                      <hr />
                      <div className="publication-container">
                        <div>
                          <p className="publication-title">
                            {publication.title}
                          </p>
                          <h4 className="publication-type">
                            {publication.type} / {publication.date}
                          </h4>
                          <p className="publication-description">
                            {publication.description}
                          </p>
                        </div>
                        <div className="publication-btn-container">
                          {publication.linkType === "URL" ? (
                            <a
                              target="_blank"
                              className="publication-btn"
                              href={publication.url}
                            >
                              Read more
                            </a>
                          ) : (
                            <a
                              className="publication-btn"
                              href={publication.file.mediaItemUrl}
                            >
                              Read more
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <hr />
                </div>
              </section>
            </>
          )}
          {/*  */}

          <div className="project-navigation section-mb">
            <div className="container">
              <div className="previous">
                {prevProject && (
                  <Link
                    to={prevProject?.node?.uri}
                    onMouseOver={() =>
                      getProject({
                        variables: { projectSlug: prevProject?.node?.uri },
                      })
                    }
                  >
                    <img
                      alt="Previous"
                      className="previous-arrow"
                      src={prevArrow}
                    />
                    <span className="h1">Previous project</span>
                  </Link>
                )}
              </div>
              <div className="next">
                {nextProject && (
                  <Link
                    to={nextProject?.node?.uri}
                    onMouseOver={() =>
                      getProject({
                        variables: { projectSlug: nextProject?.node?.uri },
                      })
                    }
                  >
                    <span className="h1">Next project</span>
                    <img alt="Next" className="next-arrow" src={nextArrow} />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  );
};

export default SingleProject;
