import { gql } from "@apollo/client";

const PUBLICATIONS_PAGE_QUERY = gql`
  query publicationsPageQuery {
    pageBy(uri: "publications") {
      id
      title
      content
      customPublicationFields {
        publications {
          title
          type
          date
          description
          linkType
          url
          file {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default PUBLICATIONS_PAGE_QUERY;
