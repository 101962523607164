import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import CONTACT_PAGE_QUERY from "../quaries/contactPageQuery";
import FadeInSection from "../components/fadeinsection";

const Contact = ({ setFooterLoad }) => {
  const { loading, error, data } = useQuery(CONTACT_PAGE_QUERY);
  useEffect(() => {
    if (!loading) {
      setFooterLoad(true);
    }
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>TRP Architects : Contact</title>
      </Helmet>
      {(data) && (
        <>
          <div className="container inner-page">
            <h1 className="title-center page-title">{data.pageBy.title}</h1>
          </div>
          <FadeInSection>
            <div className="container contacts-container">
              <div
                className="contact-item"
                dangerouslySetInnerHTML={{
                  __html: data.pageBy.customContactFields.address,
                }}
              ></div>
              <div
                className="contact-item"
                dangerouslySetInnerHTML={{
                  __html: data.pageBy.customContactFields.telephone,
                }}
              ></div>
              <div
                className="contact-item"
                dangerouslySetInnerHTML={{
                  __html: data.pageBy.customContactFields.email,
                }}
              ></div>
            </div>
          </FadeInSection>
        </>
      )}
    </>
  );
};

export default Contact;
