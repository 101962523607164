import { gql } from "@apollo/client";

const HOME_PAGE_QUERY = gql`
  query homePageQuery {
    pageBy(uri: "/") {
      id
      content
      customHomeFields {
        slider {
          animation
          duration
          transitionDuration
          animationDurationMs
          image {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
    projects(
      where: { selected: true, orderby: { field: MENU_ORDER, order: ASC } }
      first: 24
    ) {
      nodes {
        id
        uri
        title
        slug
        customProjectFields {
          location
        }
        featuredImage {
          node {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

export default HOME_PAGE_QUERY;