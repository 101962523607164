import { gql } from "@apollo/client";

const SINGLE_PROJECT_QUERY = gql`
  query singleProjectQuery($projectSlug: String) {
    project: projectBy(slug: $projectSlug) {
      id
      title
      customProjectFields {
        location
        selected
        pageElements {
          elementType
          text
          image {
            altText
            sourceUrl(size: LARGE)
          }
          image2 {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
    allProjects: projects(first: 48) {
      edges {
        node {
          id
          uri
          slug
        }
      }
    }
    awards(first: 48) {
      nodes {
        id
        title
        content
        customAwardFields {
          project
          linkedProject {
            ... on Project {
              id
              slug
            }
          }
        }
      }
    }
    publications: pageBy(uri: "publications") {
      id
      title
      content
      customPublicationFields {
        publications {
          title
          type
          date
          description
          linkType
          url
          file {
            mediaItemUrl
          }
          linkedProject {
            ... on Project {
              id
              slug
            }
          }
        }
      }
    }
  }
`;

export default SINGLE_PROJECT_QUERY;
