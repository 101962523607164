import React from "react"
import PropTypes from "prop-types"
import FadeInSection from "../fadeinsection";

const IntroText = ({ text }) => {
  return (
    <section className="intro-text section-mb">
      <FadeInSection>
        <div className="container" dangerouslySetInnerHTML={{ __html: text }}>
        </div>
      </FadeInSection>
    </section>
  )
}

IntroText.propTypes = {
  text: PropTypes.string,
}

export default IntroText
