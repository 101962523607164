import React, { useState, useEffect, useRef } from "react"

function FadeInSection(props) {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef(null);

    const callbackFunction = (entries) => {
        const [entry] = entries
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setVisible(entry.isIntersecting);
            }
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, props)

        if(domRef.current) observer.observe(domRef.current)
        return () => {
            if(domRef.current) observer.unobserve(domRef.current) 
        }
    }, [domRef,props])
    return (
        <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

export default FadeInSection