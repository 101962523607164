import React, { useEffect } from "react";

//components
import Slider from "../components/sections/Slider";
import IntroText from "../components/sections/IntroText";
import SelectedProjects from "../components/sections/SelectedProjects";
import HOME_PAGE_QUERY from "../quaries/homePageQuery";

//libs
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

const Home = ({ setFooterLoad }) => {
  const { loading, error, data } = useQuery(HOME_PAGE_QUERY);

  useEffect(() => {
    if (!loading) {
      setFooterLoad(true);
    }
    return () => {
      setFooterLoad(false);
    }
  }, [loading]);
  
  return (
    <>
      <Helmet>
        <title>TRP Architects : Home</title>
      </Helmet>
      {(data) && (
        <>
          <Slider images={data.pageBy.customHomeFields.slider} />
          <IntroText text={data.pageBy.content} />
          <div className="container">
            <h2 className="title-center">selected projects</h2>
          </div>
          <SelectedProjects projects={data.projects.nodes} />
        </>
      )}
    </>
  );
};

export default Home;
