import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import SelectedProjects from "../components/sections/SelectedProjects";
import PROJECTS_PAGE_QUERY from "../quaries/projectsPageQuery";

const Projects = ({ headerLoaded, setFooterLoad }) => {
  const { loading, error, data } = useQuery(PROJECTS_PAGE_QUERY);

  useEffect(() => {
    if (!loading) {
      setFooterLoad(true);
    }
    return () => {
      setFooterLoad(false);
    };
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>TRP Architects : Projects</title>
      </Helmet>
      {data && (
        <article>
          <div className="container">
            <section className="project-content">
              <div
                className="container"
                dangerouslySetInnerHTML={{ __html: data.pageBy.content }}
              ></div>
            </section>
          </div>
          {/* All projects */}
          <div className="container inner-page">
            <h1 className="title-center page-title">{data.pageBy.title}</h1>
          </div>
          <SelectedProjects projects={data.projects.nodes} />
        </article>
      )}
    </>
  );
};

export default Projects;
