import React from "react";

import PropTypes from "prop-types";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FadeInSection from "../fadeinsection";

const SideBySideImages = ({ data }) => {
  return (
    <FadeInSection>
    <section className="side-by-side-images section-mb">
      <div className="container">
        <LazyLoadImage
          effect="blur"
          src={data.image?.sourceUrl}
          alt={data.image?.altText}
        />
        <LazyLoadImage
          effect="blur"
          src={data.image2?.sourceUrl}
          alt={data.image2?.altText}
        />
      </div>
    </section>
    </FadeInSection>
  );
};

SideBySideImages.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SideBySideImages;
