import { gql } from "@apollo/client";

const PROJECTS_PAGE_QUERY = gql`
  query projectsPageQuery {
    pageBy(uri: "projects") {
      id
      title
      content
    }
    projects(where: { orderby: { field: MENU_ORDER, order: ASC } }, first: 48) {
      nodes {
        id
        uri
        title
        slug
        customProjectFields {
          location
        }
        featuredImage {
          node {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

export default PROJECTS_PAGE_QUERY;