import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import FadeInSection from "../fadeinsection";

const NAV_QUERY = gql`
  query navQuery {
    menu(id: "mainMenu", idType: NAME) {
      menuItems {
        nodes {
          id
          label
          uri
          childItems {
            nodes {
              id
              parentId
              label
              uri
            }
          }
        }
      }
    }
  }
`;

const Navbar = ({ setHeaderLoaded }) => {
  const { loading, error, data } = useQuery(NAV_QUERY);

  useEffect(() => {
    if (!loading) {
      setHeaderLoaded(true);
    }
    return () => {
      setHeaderLoaded(false);
    };
  }, [loading]);

  return (
    <>
      {data && (
        <nav className="nav__section">
          <div className="hamburger">
            <input className="side-menu" type="checkbox" id="side-menu" />
            <label className="hamb" htmlFor="side-menu">
              <span className="hamb-line"></span>
            </label>
            <nav className="nav">
              <ul className="menu">
                <>
                  <NavLink key="home" to='/'>
                    Home
                  </NavLink>
                  {data.menu.menuItems.nodes.map((item) => (
                    <NavLink key={item.id} to={item.uri}>
                      {item.label}
                    </NavLink>
                  ))}
                </>
              </ul>
            </nav>
          </div>
          <FadeInSection>
            <div className="container">
              <div className="navbar">
                <NavLink to="/" className="navbar__logo">
                  <h2>The regeneration practice</h2>
                </NavLink>
                <div className="navbar__menu topnav">
                  {data.menu.menuItems.nodes.map((item) => (
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "hover-underline-animation active"
                          : "hover-underline-animation"
                      }
                      key={item.id}
                      to={item.uri}
                    >
                      {item.label}
                    </NavLink>
                  ))}
                </div>
                <div></div>
              </div>
            </div>
          </FadeInSection>
        </nav>
      )}{" "}
    </>
  );
};

export default Navbar;
