import "./App.css";

import Footer from "./components/sections/Footer";
import Navbar from "./components/sections/Navbar";

import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Publications from "./pages/Publications";
import SingleProject from "./pages/SingleProject";
import Studio from "./pages/Studio";
import NotFound from "./pages/NotFound";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { useEffect, useState } from "react";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-FFHTLLHYQ2'
}

TagManager.initialize(tagManagerArgs)

function App() {
  const [client, setClient] = useState();
  const [persistor, setPersistor] = useState();

  const [footerLoad, setFooterLoad] = useState(false);
  const [headerLoaded, setHeaderLoaded] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });

    if (document.getElementById("side-menu")) {
      document.getElementById("side-menu").checked = false;
    }

    if (pathname.includes(".html")) {
      switch (pathname) {
        case "/index.html":
          navigate("/");
          break;
        default:
          const path = pathname.split(".html")[0];
          navigate(path + "/");
          break;
      }
    }
  }, [pathname]);

  const defaultOptions = {
    watchQuery: {
      // fetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      // fetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  };

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: false,
        trigger: "write",
      });
      await newPersistor.restore();
      setPersistor(newPersistor);
      setClient(
        new ApolloClient({
          uri: "https://cms.regeneration.co.uk/graphql",
          cache,
          defaultOptions,
        })
      );
    }

    init().catch(console.error);
  }, []);

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Navbar setHeaderLoaded={setHeaderLoaded} />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home setFooterLoad={setFooterLoad} />} />
          <Route
            path="/studio"
            element={<Studio setFooterLoad={setFooterLoad} />}
          />
          <Route
            path="/contact"
            element={<Contact setFooterLoad={setFooterLoad} />}
          />
          <Route
            path="/projects"
            element={
              <Projects
                headerLoaded={headerLoaded}
                setFooterLoad={setFooterLoad}
              />
            }
          />
          <Route
            path="/publications"
            element={<Publications setFooterLoad={setFooterLoad} />}
          />
          <Route
            path="/projects/:projectSlug"
            element={<SingleProject setFooterLoad={setFooterLoad} />}
          />
          <Route path="*" element={<NotFound />} status={404} />
        </Routes>
      </div>
      {footerLoad && <Footer />}
    </ApolloProvider>
  );
}

export default App;
