import React from "react"
import PropTypes from "prop-types"
import FadeInSection from "../fadeinsection";

const Text = ({ text }) => {
  return (
    <FadeInSection>
      <section className="text-section section-mb">
        <div className="container" dangerouslySetInnerHTML={{ __html: text }}>
        </div>
      </section>
    </FadeInSection>
  )
}

Text.propTypes = {
  text: PropTypes.string,
}

export default Text
