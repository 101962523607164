import { gql } from "@apollo/client";

const CONTACT_PAGE_QUERY = gql`
  query contactPageQuery {
    pageBy(uri: "contact") {
      id
      title
      content
      customContactFields {
        address: column1
        telephone: column2
        email: column3
      }
    }
  }
`;

export default CONTACT_PAGE_QUERY;
