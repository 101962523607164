import React, { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import STUDIO_PAGE_QUERY from "../quaries/studioPageQuery";
import FadeInSection from "../components/fadeinsection";

const Studio = ({ setFooterLoad }) => {
  const { loading, error, data } = useQuery(STUDIO_PAGE_QUERY);

  useEffect(() => {
    if (!loading) {
      setFooterLoad(true);
    }
    return () => {
      setFooterLoad(false);
    };
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>TRP Architects : Studio</title>
      </Helmet>
      {data && (
        <article>
          <div className="container title-center mb-50 inner-page">
            <h1 className="page-title">{data.studio.title}</h1>
          </div>
          <section className="studion-content text-section-studio mb-50">
            <div className="container">
              <FadeInSection>
                <div
                  className="columnleft"
                  dangerouslySetInnerHTML={{ __html: data.studio.content }}
                ></div>
              </FadeInSection>
              <FadeInSection>
                <div className="image-container columnright">
                  <img
                    width={"100%"}
                    src={data.studio.featuredImage?.node.sourceUrl}
                    alt={data.studio.featuredImage?.node.altText}
                  />
                </div>
              </FadeInSection>
            </div>
          </section>
          {/* AWards */}
          <div className="container title-center mb-25">
            <h2>Awards</h2>
          </div>
          <section className="awards pb-50">
            <div className="container awards-container">
              {data.awards.nodes.map((award) => (
                <FadeInSection>
                  <div className="award-item">
                    <div className="award-container">
                      <h3 className="award-title">{award.title}</h3>
                      {award.customAwardFields.linkedProject ? (
                        <a
                          href={
                            "/projects/" +
                            award.customAwardFields.linkedProject?.slug
                          }
                        >
                          <p className="award-project">
                            {award.customAwardFields.linkedProject?.title}
                          </p>
                        </a>
                      ) : (
                        <p className="award-project">
                          {award.customAwardFields.project}
                        </p>
                      )}
                      <div
                        className="award-content"
                        dangerouslySetInnerHTML={{ __html: award.content }}
                      ></div>
                    </div>
                  </div>
                </FadeInSection>
              ))}
            </div>
          </section>

          <div className="container">
            <section className="awards"></section>
          </div>
        </article>
      )}
    </>
  );
};

export default Studio;
