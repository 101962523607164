import React from "react";

import PropTypes from "prop-types";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import SelectedProjectItem from "../elements/SelectedProjectItem";

const SelectedProjects = ({ projects, client }) => {
  return (
    <section className="selected-projects section-mb">
      <div className="container">
        <div className="masonry-container">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 530: 2, 900: 3 }}
          >
            <Masonry gutter="16px">
              {projects.map((project) => (
                <SelectedProjectItem key={project.id} project={project} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </section>
  );
};

SelectedProjects.propTypes = {
  projects: PropTypes.array,
};

export default SelectedProjects;
