import React from "react";
import PropTypes from "prop-types";

import SINGLE_PROJECT_QUERY from "../../quaries/singleProjectQuery";

//libs
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SelectedProjectItem = ({ project }) => {
  const [getProject] = useLazyQuery(SINGLE_PROJECT_QUERY);

  return (
    <div className="selected-project-item">
      <Link
        to={project.uri}
        onMouseOver={() =>
          getProject({
            variables: { projectSlug: project.slug },
          })
        }
      >
        <div className="description">
          <LazyLoadImage
            effect="blur"
            src={project.featuredImage?.node.sourceUrl}
            alt={project.featuredImage?.node.altText}
          />
          <h3>{project.title}</h3>
          <p>{project.customProjectFields.location}</p>
        </div>
      </Link>
    </div>
  );
};

SelectedProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
};

export default SelectedProjectItem;
