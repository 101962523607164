import React, { useState, useEffect } from "react";

import { animated, useTransition } from "@react-spring/web"
import FadeInSection from "../fadeinsection";

const Slider = React.memo(({ images }) => {
  const [animation, setAnimation] = useState(0);
  const [position, setPosition] = useState(0);

  const imagearr = images;

  const transitions = useTransition(position, animation);
  useEffect(() => {
    let time = 0
    let repeats = 0
    let position = 0
    let fd_op = 0

    for (let index = 0; index < imagearr.length; index++) {
      time = time + imagearr[index].duration;
      position = (index) => (index + 1) % imagearr.length;
      let animationstyle = (index + 1) % imagearr.length;
      let fadetime = index % imagearr.length;
      setTimeout(() => {
        let animation_duration = imagearr[animationstyle].animationDurationMs
        let fade_duration = imagearr[fadetime].transitionDuration
        //set animation
        switch (imagearr[animationstyle].animation) {
          case "Zoom in":
            const zoomInAnimation = {
              from: { opacity: 0, transform: "scale(1)" },

              enter: item => async (next, cancel) => {
                next({ opacity: 1 ,config: { duration: fade_duration } })
                next({ transform: "scale(1.2)",config: { duration: animation_duration  } })
              },

              leave: { opacity: 0, config: { duration: 1000 } },
            }
            setAnimation(zoomInAnimation)
            break
          case "Zoom out":
            const zoomOutAnimation = {
              from: { opacity: 0, transform: "scale(1.2)" },

              enter: item => async (next, cancel) => {
                next({ opacity: 1 ,config: { duration: fade_duration } })
                next({ transform: "scale(1)",config: { duration: animation_duration  } })
              },
              leave: { opacity: 0, delay: fade_duration },
            }
            setAnimation(zoomOutAnimation)
            break
            case "Pan right to left":
            const RtoLAnimation = {
              from: { opacity: 0, transform: "scale(125%) translateX(10%)"},
              enter: item => async (next, cancel) => {
                next({ opacity: 1 ,config: { duration: fade_duration } })
                next({ transform: "scale(125%) translateX(0)",config: { duration: fade_duration } })
              },
              leave: { opacity: 0, config: { duration: 1000 } },
            }
            setAnimation(RtoLAnimation)
            break
            case "Pan left to right":
            const LtoRAnimation = {
              from: { opacity: 0, transform: "scale(125%) translateX(-10%)" },
              enter: item => async (next, cancel) => {
                next({ opacity: 1 ,config: { duration: fade_duration } })
                next({ transform: "scale(125%) translateX(0)",config: { duration: fade_duration } })
              },
              leave: { opacity: 0, delay: fade_duration },
            }
            setAnimation(LtoRAnimation)
            break
            case "No animation":
            const noAnimation = {
              from: { opacity: fade_duration == null ? 1 : 0 },
              enter: item => async (next, cancel) => {
                await next({ opacity: 1, })
              },
              leave: { opacity: fade_duration == null ? 1 : 0, delay: fade_duration == null ? 0 : fade_duration },

              config: { duration: fade_duration },
            }
            setAnimation(noAnimation)
            break
          default:
            const defaultAnimation = {
              from: { opacity: 1 },
              enter: item => async (next, cancel) => {
                await next({ opacity: 1, })
              },
              leave: { opacity: 1, delay: fade_duration },

              config: { duration: animation_duration },
            };
            setAnimation(defaultAnimation);
            break;
        }
        //set image index
        setPosition(position);
      }, time);

      if (index === imagearr.length - 1 && repeats < 10) {
        index = 0;
        repeats++;
        continue;
      }
    }
  }, [imagearr]);

  return (
    <section className="slider section-mb">
      <div id="preload">
        {imagearr.map((item) => (
          <img src={item.image.sourceUrl} />
        ))}
      </div>
      <div className="container">
        <FadeInSection>
          <div className="sliderinner">
            {transitions((style, index) => (
              <>
                <animated.div
                  className={index === 0 ? "active" : ""}
                  style={{
                    ...style,
                    width: "100%",
                    paddingBottom: "47%",
                    position: "absolute",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${imagearr[index].image.sourceUrl})`,
                  }}
                />
              </>
            ))}
          </div>
        </FadeInSection>
      </div>
    </section>
  );
});

export default Slider;
