import React from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import PUBLICATIONS_PAGE_QUERY from "../quaries/publicationsPageQuery";
import { useEffect } from "react";

const Publications = ({ setFooterLoad }) => {
  const { loading, error, data } = useQuery(PUBLICATIONS_PAGE_QUERY);

  useEffect(() => {
    if (!loading) {
      setFooterLoad(true);
    }
    return () => {
      setFooterLoad(false);
    };
  }, [loading]);
  return (
    <>
      <Helmet>
        <title>TRP Architects : Publications</title>
      </Helmet>
      {data && (
        <article>
          <div className="container title-center mb-50 inner-page">
            <h1 className="page-title">{data.pageBy.title}</h1>
          </div>
          <section className="publications mb-50">
            <div className="container">
              {data.pageBy.customPublicationFields?.publications.map(
                (publication) => (
                  <div className="outer">
                    <hr />
                    <div className="publication-container">
                      <div>
                        <p className="publication-title">{publication.title}</p>
                        <h4 className="publication-type">
                          {publication.type} / {publication.date}
                        </h4>
                        <p className="publication-description">
                          {publication.description}
                        </p>
                      </div>
                      <div className="publication-btn-container">
                        {publication.linkType === "URL" ? (
                          <a target="_blank" className="publication-btn" href={publication.url}>
                            Read more
                          </a>
                        ) : (
                          <a
                            className="publication-btn"
                            href={publication.file.mediaItemUrl}
                          >
                            Read more
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
              <hr />
            </div>
          </section>
        </article>
      )}
    </>
  );
};

export default Publications;
