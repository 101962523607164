import React from "react";

import ribaLogo from "../../../src/assets/images/footer-logo-riba.svg";
import arbLogo from "../../../src/assets/images/footer-logo-arb.svg";
import hesprLogo from "../../../src/assets/images/footer-logo-hespr.svg";
import { gql, useQuery } from "@apollo/client";
import FadeInSection from "../fadeinsection";

const FOOTER_QUERY = gql`
  query footerQuery {
    themeGeneralSettings {
      themeOptions {
        footerAddress
        footerEmail
        footerPhone
      }
    }
  }
`;

const Footer = () => {
  const { loading, error, data } = useQuery(FOOTER_QUERY);
  return (
    <>
      {(data) && (
        <footer>
          <FadeInSection>
            <div className="container">
              <div className="footer-container">
                <div className="footer--left">
                  <h3 className="footer-title">The regeneration practice</h3>
                  <br />
                  <p className="display-linebreak">
                    {data.themeGeneralSettings.themeOptions.footerAddress}
                  </p>
                  <br />
                  <p>Tel: {data.themeGeneralSettings.themeOptions.footerPhone}</p>
                  <p>
                    Email: : {data.themeGeneralSettings.themeOptions.footerEmail}
                  </p>
                  <br />
                  <p>Privacy and cookies</p>
                  <p>&#169; {new Date().getFullYear()} TRP Architects</p>
                </div>
                <div className="footer--right">
                  <img alt="logo" className="footer-image" src={ribaLogo} />
                  <img alt="logo" className="footer-image" src={arbLogo} />
                  <img alt="logo" className="footer-image" src={hesprLogo} />
                </div>
              </div>
            </div>
          </FadeInSection>
        </footer>
      )}
    </>
  );
};

export default Footer;
