import React from "react";

import PropTypes from "prop-types";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FadeInSection from "../fadeinsection";

const SingleImage = ({ data }) => {
  return (
    <FadeInSection>
      <section className="single-image section-mb">
        <div className="container">
          <LazyLoadImage
            effect="blur"
            src={data.image?.sourceUrl}
            alt={data.image?.altText}
          />
        </div>
      </section>
    </FadeInSection>
  );
};

SingleImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SingleImage;
